<template>
  <div class="popup-add-guide">
    <div class="popup-add-guide__wrapper">
      <Column class="grid-md-6 grid-lg-6">
        <p class="popup-add-guide__title">{{ $t('popup.become_guide.become_guide') }}</p>
        <p class="popup-add-guide__description">{{ $t('popup.become_guide.desc') }}</p>
        <InputComponent id="guideSurname"
          :label="$t('popup.common.fullname')"
          type="text"
          :disabled="this.$popup.additions && this.$popup.additions.id"
          v-model="guideName"/>
        <InputComponent id="guidePhone"
          :label="$t('popup.common.phone')"
          type="text"
          :disabled="this.$popup.additions && this.$popup.additions.id"
          v-model="guidePhone"/>
        <InputComponent id="guideEmail"
          :label="$t('popup.common.email')"
          type="text"
          :disabled="this.$popup.additions && this.$popup.additions.id"
          v-model="guideEmail"/>
        <SelectComponent id="guideCity"
          :label="$t('popup.common.city')"
          itemKey="name"
          :index="guideCity"
          v-on:change-index="changeIndex"
          :array="$cities.data"/>
        <InputComponent id="guideAnnotation"
          :label="$t('popup.common.annotation')"
          multiline
          maxChars="700"
          v-model="guideAnnotation"/>
        <InputComponent id="guideDescription"
          :label="$t('popup.common.desc')"
          multiline
          maxChars="700"
          v-model="guideDescription"/>
        <Info>
          <p class="popup-add-object__info-text">{{ $t('popup.become_guide.info') }}</p>
        </Info>
        <InputComponent id="vk"
          label="vk"
          type="text"
          v-model="vk"/>
        <InputComponent id="facebook"
          label="facebook"
          type="text"
          v-model="facebook"/>
        <InputComponent id="instagram"
          label="instagram"
          type="text"
          v-model="instagram"/>
        <InputComponent id="youtube"
          label="youtube"
          type="text"
          v-model="youtube"/>
        <InputComponent id="ok"
          label="ok"
          type="text"
          v-model="ok"/>
        <div>
          <Button v-on:click.native="submit"
            color="main">{{ $t('popup.common.send_form') }}
          </Button>
        </div>
      </Column>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupAddGuide',
  data() {
    return {
      guideName: '',
      guidePhone: '',
      guideEmail: '',
      guideDescription: '',
      guideAnnotation: '',
      guideCity: 3,
      vk: '',
      facebook: '',
      instagram: '',
      youtube: '',
      ok: '',
    };
  },
  methods: {
    changeIndex(id) {
      this.guideCity = id;
    },
    submit() {
      let guide = '?';
      if (this.guideName) guide += `name=${this.guideName}`;
      if (this.guideAnnotation) guide += `&annotation=${this.guideAnnotation}`;
      if (this.guideDescription) guide += `&description=${this.guideDescription}`;
      if (this.guidePhone) guide += `&phone=${this.guidePhone}`;
      if (this.guideEmail) guide += `&email=${this.guideEmail}`;
      if (this.guideCity) guide += `&city_id=${this.guideCity}`;
      guide += `&socials={"vk":"${this.vk}","facebook":"${this.facebook}","instagram":"${this.instagram}","youtube":"${this.youtube}","ok":"${this.ok}"}`;
      if (this.$popup.additions && this.$popup.additions.id) {
        this.$putAddGuide(this.$popup.additions.id, guide);
      } else {
        this.$postAddGuide(guide);
      }
      this.guideName = '';
      this.guideAnnotation = '';
      this.guideDescription = '';
      this.guidePhone = '';
      this.guideEmail = '';
    },
  },
  created() {
    if (this.$popup.additions && this.$popup.additions.name) {
      this.guideName = this.$popup.additions.name;
    }
    if (this.$popup.additions && this.$popup.additions.description) {
      this.guideDescription = this.$popup.additions.description;
    }
    if (this.$popup.additions && this.$popup.additions.annotation) {
      this.guideAnnotation = this.$popup.additions.annotation;
    }
    if (this.$popup.additions && this.$popup.additions.phone) {
      this.guidePhone = this.$popup.additions.phone;
    }
    if (this.$popup.additions && this.$popup.additions.email) {
      this.guideEmail = this.$popup.additions.email;
    }
    if (this.$popup.additions && this.$popup.additions.city_id) {
      this.guideCity = this.$popup.additions.city_id;
    }
    if (this.$popup.additions && this.$popup.additions.socials) {
      this.vk = this.$popup.additions.socials.vk;
      this.facebook = this.$popup.additions.socials.facebook;
      this.instagram = this.$popup.additions.socials.instagram;
      this.youtube = this.$popup.additions.socials.youtube;
      this.ok = this.$popup.additions.socials.ok;
    }
  },
};
</script>
